.container {
    margin-top: 30px;
    padding: 0 30px;
}

.heading {
    font-size: 3em;
}

.form {
    padding: 30px 30px;
    margin: 15% 30px;
    border: green 5px solid;
    border-radius: 20px;
    background-color: rgb(220, 248, 220);
}

.form-label {
    color: red;
    font-size: 20px;
    font-weight: bold;
}

.nav-link {
    color: green;
    font-weight: bold;
    /* font-size: 15px; */
}

.link {
    color: green;
    text-decoration-line: none;
}

.box {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.card {
    background-color: rgb(220, 248, 220);
}

.scrollable-container {
    overflow-y: auto;
    max-height: 1000px;
}

.footer {
    margin-top: 200px;
}
